<template>
  <div class="row">

    <div class="col-md-12">
      <Card title="Detail Kategori Pengeluaran">
        <template v-slot:body>
          <div class="row align-items-center">

            <!-- Splash Image -->
            <div class="col-md-4">
              <div class="d-flex justify-content-center align-items-center image-container">
                <div style='height: 350px; width: 350px; position: relative'>
                  <img
                    class="image"
                    src="/images/expense-splash.svg"
                    @load="onImageLoad()"
                  >
                </div>
              </div>
            </div>

            <!-- Table Data -->
            <div
              class="col"
              v-if="imgLoaded"
            >
              <table class="table mb-3">
                <tr>
                  <td style="width: 25%"><strong>Nama</strong></td>
                  <td>{{ data.name }}</td>
                </tr>
                <tr>
                  <td><strong>Deskripsi</strong></td>
                  <td>{{ data.description }}</td>
                </tr>
              </table>

              <button
                v-b-modal.modal-form
                @click="btnEditOnClick(data.id)"
                class="btn mx-1 btn-success"
                v-if="manipulateBtn == true"
              ><i class="far fa-fw fa-edit"></i> Edit</button>
              <button
                @click="deleteData"
                v-if="manipulateBtn == true"
                class="btn mx-1 btn-danger"
              ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
            </div>

          </div>
        </template>
      </Card>
    </div>

    <b-modal
      id="modal-form"
      :static="true"
      title="Ubah Kategori Pengeluaran"
      @ok="modalOnSubmit"
      @hidden="modalOnHide"
    >
      <Form
        :form="form"
        route="pharmacy-expense-categories/"
        :editTarget="editTarget"
        ref="form"
        purpose="master"
        @formOnSubmit="formOnSubmit"
      />
    </b-modal>

  </div>
</template>

<script>

import Form from '@/component/pharmacy/pharmacy-expense-categories/Form.vue'
import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      // Data
      data: {
        name: "",
        description: ""
      },
      form: {
        name: "",
        description: ""
      },
      // Other
      imgLoaded: false,
      editTarget: '',
      manipulateBtn:false
    }
  },

  components: {
    Card,
    Form
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async get() {
      this.data = await module.get('pharmacy-expense-categories/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/pharmacy-expense-categories/list')
      }
    },

    modalOnSubmit(evt) {
      this.$refs.form.modalOnSubmit(evt)
    },

    modalOnHide() {
      this.$refs.form.modalOnHide()
    },

    formOnSubmit() {
      this.get()
    },

    async btnEditOnClick(id) {
      this.editTarget = id.toString()
      this.form = await module.get('pharmacy-expense-categories/' + id)
      this.form['_method'] = 'put'
    },

    async deleteData() {
      let result = await module.delete('pharmacy-expense-categories/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/pharmacy/pharmacy-expense-categories/list')
      }
    },
    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      console.log("looping")
      
      if(access_right[a] == "1405"){
        this.manipulateBtn = true
      }
      
    }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apotek", route:"/pharmacy/dashboard" },
      { title: "Kategori Pengeluaran", route: "/pharmacy/pharmacy-expense-categories/list" },
      { title: "Detail" },
    ])
    // Get Data
    this.get()
    this.setActiveMenu()
  },

}
</script>

<style>
</style>